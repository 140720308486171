import React from 'react';
import classnames from 'classnames';

import styles from './Preloader.module.scss';

function Preloader({ isActive }: { isActive: boolean }) {
  React.useEffect(() => {
    if (isActive) {
      document.body.classList.add(styles.preloader);
    } else {
      document.body.classList.remove(styles.preloader);
    }
  }, [isActive]);

  return (
    <div className={classnames(styles.preloaderOverlay, { [styles.active]: isActive })}>
      {isActive && (
        <svg
          className={styles.preloaderSpinner}
          width="314px"
          height="314px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <defs>
            <filter
              id="ldio-1s8reqtrtdu-filter"
              x="-100%"
              y="-100%"
              width="300%"
              height="300%"
              colorInterpolationFilters="sRGB"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
              <feComponentTransfer result="cutoff">
                <feFuncA type="table" tableValues="0 0 0 0 0 0 1 1 1 1 1" />
              </feComponentTransfer>
            </filter>
          </defs>
          <g filter="url(#ldio-1s8reqtrtdu-filter)">
            <g transform="translate(50 50)">
              <g transform="rotate(326.673)">
                <circle cx="15" cy="0" r="3.23939" fill="#ff3800">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="3;7;3"
                    dur="2.857142857142857s"
                    repeatCount="indefinite"
                    begin="-0.35s"
                  />
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="2.857142857142857s"
                  repeatCount="indefinite"
                  begin="0s"
                />
              </g>
            </g>
            <g transform="translate(50 50)">
              <g transform="rotate(310.985)">
                <circle cx="15" cy="0" r="3.08679" fill="#6adf8b">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="3;7;3"
                    dur="1.4285714285714286s"
                    repeatCount="indefinite"
                    begin="-0.27999999999999997s"
                  />
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="1.4285714285714286s"
                  repeatCount="indefinite"
                  begin="-0.06999999999999999s"
                />
              </g>
            </g>
            <g transform="translate(50 50)">
              <g transform="rotate(312.94)">
                <circle cx="15" cy="0" r="3.45779" fill="#5e91ff">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="3;7;3"
                    dur="0.9523809523809526s"
                    repeatCount="indefinite"
                    begin="-0.20999999999999996s"
                  />
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="0.9523809523809526s"
                  repeatCount="indefinite"
                  begin="-0.13999999999999999s"
                />
              </g>
            </g>
            <g transform="translate(50 50)">
              <g transform="rotate(332.532)">
                <circle cx="15" cy="0" r="4.39438" fill="#3308c2">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="3;7;3"
                    dur="0.7142857142857143s"
                    repeatCount="indefinite"
                    begin="-0.13999999999999999s"
                  />
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="0.7142857142857143s"
                  repeatCount="indefinite"
                  begin="-0.20999999999999996s"
                />
              </g>
            </g>
            <g transform="translate(50 50)">
              <g transform="rotate(9.76627)">
                <circle cx="15" cy="0" r="5.72297" fill="#ff3800">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="3;7;3"
                    dur="0.5714285714285714s"
                    repeatCount="indefinite"
                    begin="-0.06999999999999999s"
                  />
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="0.5714285714285714s"
                  repeatCount="indefinite"
                  begin="-0.27999999999999997s"
                />
              </g>
            </g>
          </g>
        </svg>
      )}
    </div>
  );
}

Preloader.defaultProps = {
  isActive: false,
};

export default Preloader;
